





























import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { IOption } from "@/models/interface-v2/common.interface";
import { InvoicePrepayment, InvoicePrepaymentData } from "@/models/interface-v2/invoice-prepayment.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoicePrepayment } from "@/services-v2/invoice-prepayment.service";
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "InvoiceSelectPrepayment",
  mixins: [
    MNotificationVue
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    },
    propSearchBy: {
      type: String,
      default: undefined,
    },
  },
  data() {
    this.search = debounceProcess(this.search, 300);
    return {
      loading: false,
      dtProducts: {} as InvoicePrepayment,
      dtOpt: [] as IOption<InvoicePrepaymentData>[],
      queryParams: {
        page: 0,
        limit: 10,
        search: "",
      },
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.invoiceStore.form,
    })
  },
  watch: {
    propSearchBy(newVal): void {
      if (newVal) {
        // this.queryParams.search = newVal;
        this.getList(this.queryParams);
      }
    },
  },
  created() {
    if (this.propSearchBy) this.queryParams.search = this.propSearchBy;
    this.getList(this.queryParams);
  },
  methods: {
    search(search = ""): void {
      this.loading = true;
      const searchBy: string[] = [];
      if (search) searchBy.push(`invoicePrepaymentNumber~*${search}*`);
      if (this.propSearchBy) searchBy.push(this.propSearchBy);
      this.queryParams.search = searchBy.join("_AND_");
      this.queryParams.page = 0;
      this.dtOpt = [];
      this.getList(this.queryParams);
    },
    onSelect(e: string, meta: InvoicePrepaymentData): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    popupScroll(e): void {
      if ((this.dtProducts.totalPages - 1) === this.dtProducts.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await invoicePrepayment.getListInvoicePrepayment(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.invoicePrepaymentNumber, value: x.id, meta: x }));
        this.dtOpt = [...dtOpt, ...opts];
        this.dtProducts = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    }
  }
});
